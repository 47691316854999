import { ReactElement } from '@scandipwa/scandipwa/src/type/Common.type';

import {
    SliderComponent as SourceSliderComponent,
} from 'SourceComponent/Slider/Slider.component';

/** @namespace Inov8/Component/Slider/Component */
export class SliderComponent extends SourceSliderComponent {
    render(): ReactElement {
        const { mix } = this.props;

        return (
            <>
                <div
                  block="Slider"
                  mix={ mix }
                  ref={ this.getSliderRef() }
                >
                    { this.renderSliderContent() }
                    { /* { this.renderCrumbs() } */ }
                    { this.renderCounter() }

                </div>
                { this.renderArrows() }
            </>
        );
    }
}

export default SliderComponent;
